import classNames from "classnames";
import { FieldError, RegisterOptions } from "react-hook-form";
import { InputError } from "../InputError";
import {
  listsPlugin,
  markdownShortcutPlugin,
  MDXEditor,
  toolbarPlugin,
  BoldItalicUnderlineToggles,
  ListsToggle,
} from "@mdxeditor/editor";
import "@mdxeditor/editor/style.css";

import "./index.scss";
import { useIsClient } from "usehooks-ts";

type RichTextInputProps = {
  id: string;
  label: string;
  className?: string;
  error?: FieldError | undefined;
  onChange: (value: string) => void;
  initialValue?: string;
  registerOptions?: RegisterOptions;
  asRow?: boolean;
} & Pick<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  "className" | "autoFocus" | "placeholder"
>;

export function RichTextInput(props: RichTextInputProps) {
  const {
    id,
    label,
    className,
    onChange,
    error,
    registerOptions,
    autoFocus,
    initialValue,
    asRow,
  } = props;
  const isClient = useIsClient();

  return (
    <div
      className={classNames(asRow && "grid grid-cols-[24px_1fr]", className)}
    >
      <label htmlFor={id} className="text-secondary-600 text-xs">
        {registerOptions?.required ? label + "*" : label}
      </label>
      <div className="relative flex flex-col">
        {isClient && (
          <MDXEditor
            suppressHtmlProcessing
            plugins={[
              // Example Plugin Usage
              listsPlugin(),
              markdownShortcutPlugin(),
              toolbarPlugin({
                toolbarContents: () => (
                  <>
                    <BoldItalicUnderlineToggles options={["Bold", "Italic"]} />
                    <ListsToggle options={["bullet", "number"]} />
                  </>
                ),
              }),
            ]}
            markdown={initialValue || ""}
            autoFocus={autoFocus}
            onChange={onChange}
          />
        )}
      </div>
      <InputError>{error?.message}</InputError>
    </div>
  );
}
