/* istanbul ignore file */

import { UseFormReturn } from "react-hook-form";
import Input from "~/components/Backroom/Input";
import { validateIBAN, validateBIC } from "ibantools";
import { Inputs } from "../useStoreForm";
import InfoModal from "~/components/Backroom/Infomodal";
import Button from "~/components/Button";

type BankProps = {
  form: UseFormReturn<Inputs>;
};

export const Bank = ({ form }: BankProps) => {
  const { register, formState, watch, setValue } = form;

  const stripeAccountId = watch("stripeAccountId");

  const onResetBankInfo = () => {
    setValue("stripeAccountId", "");
    setValue("bankAccountFirstName", "");
    setValue("bankAccountLastName", "");
    setValue("bankAccountIban", "");
    setValue("bankAccountSwiftBic", "");
  };

  return (
    <section className="mb-12">
      <h2 className="text-2xl mb-2.5">Udbetalingskonto</h2>

      {stripeAccountId ? (
        <div className="bg-secondary-100 p-5 rounded-lg mb-12">
          <p>
            Vi har allerede oprettet dig i vores betalingssystem. Erstat kun
            dine betalingsoplysninger, hvis noget har ændret sig.
          </p>
          <Button
            variant="secondary"
            className="mt-5"
            size="md"
            onClick={onResetBankInfo}
          >
            Erstat bankoplysninger
          </Button>
        </div>
      ) : (
        <p className="text-secondary-800 mb-12">
          Her skal du skrive oplysningerne på den konto, hvor du ønsker dine
          udbetalinger.
        </p>
      )}

      <div>
        <div className="grid grid-cols-[1fr_1fr] gap-x-2.5 mb-5">
          <Input
            id="bankAccountFirstName"
            label="Kontoindehavers fornavn:"
            placeholder="Hans"
            register={register}
            readOnly={Boolean(stripeAccountId)}
            error={formState.errors.bankAccountFirstName}
            registerOptions={{
              required: "Fornavn er påkrævet",
            }}
          />
          <Input
            id="bankAccountLastName"
            label="Kontoindehavers efternavn:"
            placeholder="Jensen"
            register={register}
            readOnly={Boolean(stripeAccountId)}
            error={formState.errors.bankAccountLastName}
            registerOptions={{
              required: "Efternavn er påkrævet",
            }}
          />
        </div>
        <div className="relative mb-5">
          <div className="absolute right-0">
            <InfoModal id="IbanDescription" label="">
              <p className="mb-8">
                IBAN er en internationale måde at skrive et kontonummer på. Du
                kan finde dit IBAN-nummer i din Netbank og Mobilbank under
                kontooplysninger.
              </p>
            </InfoModal>
          </div>

          <Input
            id="bankAccountIban"
            label="IBAN:"
            // placeholder="DK9520000123456789"
            // TODO: Change this back at some point.
            // The current placeholder is the Stripe Test IBAN.
            placeholder="DK5000400440116243"
            register={register}
            className="mb-5"
            readOnly={Boolean(stripeAccountId)}
            error={formState.errors.bankAccountIban}
            registerOptions={{
              required: "IBAN er påkrævet",
              validate: (value) => {
                if (!validateIBAN(value).valid) {
                  return "Indtast venligst et korrekt IBAN";
                }
              },
            }}
          />
        </div>
        <div className="relative">
          <div className="absolute right-0">
            <InfoModal id="SwiftDescription" label="">
              <p className="mb-8">
                SWIFT/BIC er din banks id-nummer og findes typisk nederst på
                bankens hjemmeside, sammen med f.eks. adresse.
              </p>
            </InfoModal>
          </div>
          <Input
            id="bankAccountSwiftBic"
            label="SWIFT/BIC:"
            placeholder="LOSADKKK"
            register={register}
            className="mb-12"
            readOnly={Boolean(stripeAccountId)}
            error={formState.errors.bankAccountSwiftBic}
            registerOptions={{
              required: "SWIFT/BIC er påkrævet",
              validate: (value) => {
                if (!validateBIC(value).valid) {
                  return "Indtast venligst et korrekt SWIFT/BIC";
                }
              },
            }}
          />
        </div>
      </div>
    </section>
  );
};
