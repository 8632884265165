import { useEffect, useState } from "react";
import { Inputs } from "../useStoreForm";
import { UseFormReturn } from "react-hook-form";
import { UncontrolledTextarea } from "../../UncontrolledTextarea";
import Button from "~/components/Button";
import classNames from "classnames";
import {
  formatAddress,
  getAddressComponents,
} from "../../AddressInput/googleMapsHelpers";
import { UncontrolledAddressInput } from "../../UncontrolledAddressInput";

type Props = { form: UseFormReturn<Inputs> };

export const OtherStoresSection = ({ form }: Props) => {
  const { setValue, getValues } = form;
  const [storeInputs, setStoreInputs] = useState<Inputs["otherStores"]>(
    getValues("otherStores")
  );

  const onChangeName = (name: string, index: number) => {
    // This method only gets called when storeInputs is not undefined
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const newInputs = [...storeInputs!];
    const currentInput = newInputs[index];
    currentInput.name = name;

    setStoreInputs(newInputs);
  };

  useEffect(() => {
    const cleanedValues = storeInputs?.filter((input) => input.name);

    if (!cleanedValues) return;

    setValue("otherStores", cleanedValues);
  }, [JSON.stringify(storeInputs)]);

  const onChangeAddress = (
    place: google.maps.places.PlaceResult | null,
    index: number
  ) => {
    if (!place) return;

    // This method only gets called when storeInputs is not undefined
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const newInputs = [...storeInputs!];
    const currentInput = newInputs[index];

    const addressComponents = getAddressComponents(place);
    if (!addressComponents) return;

    const address = formatAddress(addressComponents);
    currentInput.address = address;

    setStoreInputs(newInputs);
  };

  const onAddInput = () => {
    const newInput = { name: "", address: "", createdAt: Date.now() };
    const updatedInputs = storeInputs
      ? storeInputs.concat(newInput)
      : [newInput];

    setStoreInputs(updatedInputs);
  };

  const onRemoveInput = (index: number) => {
    // This method only gets called when storeInputs is not undefined
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const newInputs = [...storeInputs!];
    newInputs.splice(index, 1);

    setStoreInputs(newInputs);
  };

  const isAddButtonDisabled =
    storeInputs?.at(-1)?.name === "" || storeInputs?.at(-1)?.address === "";

  return (
    <div className="mb-10">
      <h2 className="text-2xl mb-2.5">Andre butikker</h2>
      <p
        className={classNames(
          "text-secondary-800",
          storeInputs?.length ? "mb-6" : "mb-4"
        )}
      >
        Hvis dine varer kan købes i andres fysiske butikker, kan du vælge at
        tilføje dem her.
      </p>

      {storeInputs?.map((input, index) => (
        <div className="mb-6" key={input.createdAt}>
          <UncontrolledTextarea
            id={"otherStores-name-" + input.createdAt}
            label="Butiksnavn:*"
            className="mb-2"
            placeholder="Æblehavens Boghandel"
            initialValue={input.name}
            onChange={async (e) => {
              onChangeName(e.target.value, index);
            }}
            rows={2}
          />
          <UncontrolledAddressInput
            id={"otherStores-address-" + input.createdAt}
            placeholder="Æblehavevej 32, 2860 Søborg"
            label="Butikkens adresse:*"
            initialValue={input.address}
            onSelectPlace={(place) => onChangeAddress(place, index)}
          />

          <Button
            onClick={() => onRemoveInput(index)}
            variant="secondary"
            size="sm"
            className="mt-2 ml-auto block"
          >
            Slet butik
          </Button>
        </div>
      ))}
      <Button
        onClick={onAddInput}
        className="flex items-center justify-center w-full text-center"
        variant="primary-light"
        size="sm"
        disabled={isAddButtonDisabled}
      >
        Tilføj butik
      </Button>
    </div>
  );
};
