import Link from "next/link";
import Input from "../../Input";
import { UseFormReturn } from "react-hook-form";
import { Inputs } from "../useStoreForm";
import { FreeDeliveryInputs } from "./FreeDeliveryInputs";

type Props = {
  form: UseFormReturn<Inputs>;
  className?: string;
};

export const ShipmondoInputs = ({ form, className }: Props) => {
  const { register, watch, formState } = form;

  const shipmondoUsername = watch("shipmondoUsername");
  const shipmondoPassword = watch("shipmondoPassword");

  return (
    <div className={className}>
      <p className="mb-12">
        Tilbyd fleksibel levering med GLS, Bring og PostNord og lad dine kunder
        selv vælge, hvor de vil hente deres pakker, når de vælger levering til
        pakkeshop. Du kan bl.a. fastsætte fragtpris efter bestemte variabler,
        tilbyde gratis fragt og tilbyde flere transportører.
      </p>

      {shipmondoUsername === "redacted" && shipmondoPassword === "redacted" ? (
        <div className="bg-secondary-100 p-4 rounded-lg mb-12">
          <p className="italic mb-4">
            Du har allerede tilføjet dine Shipmondo-oplysninger.
          </p>

          <p>
            Før dine produkter kan sendes, skal du opdatere deres vægt. Ellers
            risikerer du en ekstra udgift.
          </p>
        </div>
      ) : (
        <>
          <p className="mb-4">Alt du skal gøre er at følge disse 5 trin:</p>
          <ol className="list-decimal mb-4">
            <li>
              <Link
                className="underline"
                href="https://app.shipmondo.com/account/sign-up"
                target="_blank"
              >
                Klik på linket her
              </Link>{" "}
              og opret dig som bruger
            </li>
            <li>
              <Link
                className="underline"
                href="https://app.shipmondo.com/main/app/#/setting/payment"
                target="_blank"
              >
                Klik på linket her
              </Link>{" "}
              for at tilføje betalingskort og opsætte automatisk optankning til
              din Shipmondo-konto, så du sikrer dig, at betaling sker
              automatisk.
            </li>

            <li>
              <Link
                className="underline"
                href="https://app.shipmondo.com/main/app/#/setting/api"
                target="_blank"
              >
                Klik på linket her
              </Link>{" "}
              for at komme til siden hvor du kan generere nøgler til feltene
              herunder
            </li>
            <li>Klik på “Generer nøgle”</li>
            <li>Kopier nøglerne og indsæt i felterne herunder</li>
          </ol>

          <Input
            className="mb-4"
            register={register}
            registerOptions={{ required: "Shipmondo-bruger er påkrævet." }}
            error={formState.errors.shipmondoUsername}
            id="shipmondoUsername"
            label="Shipmondo-bruger:"
            placeholder="Eks.: b9c03056-90d1-4de6-b65d-82e3fe2e4d5d"
          />

          <Input
            className="mb-12"
            register={register}
            registerOptions={{ required: "Shipmondo-nøgle er påkrævet." }}
            error={formState.errors.shipmondoPassword}
            id="shipmondoPassword"
            label="Shipmondo-nøgle:"
            placeholder="Eks.: c9c03056-50d1-3de6-x65d-57e3fe2e4dtd1"
          />
        </>
      )}

      <FreeDeliveryInputs form={form} className="mb-12" />
    </div>
  );
};
