import { UseFormReturn } from "react-hook-form";
import { Inputs } from "./useStoreForm";
import {
  DescriptionStep,
  previewObjects as descriptionPreviews,
} from "./DescriptionStep";
import {
  ProfileImageStep,
  previewObjects as profileImagePreviews,
} from "./ProfileImageStep";
import {
  FeaturedImageStep,
  previewObjects as featuredImagePreviews,
} from "./FeaturedImageStep";
import { ContactStep, previewObjects as contactPreviews } from "./ContactStep";
import {
  CompanyDetailsStep,
  previewObjects as companyDetailsPreviews,
} from "./CompanyDetailsStep";
import {
  ShippingStep,
  previewObjects as shippingPreviews,
} from "./ShippingStep";
import {
  OpeningHoursStep,
  previewObjects as openingHoursPreviews,
} from "./OpeningHoursStep";
import { Step } from "~/hooks/useSteps";
import { IconType } from "~/assets/icons/iconPaths";
import { PreviewElement } from "~/components/Backroom/Preview";
import { StrapiStore } from "~/shared-types";

export type FormEditStoreStepIds =
  | "storeDescription"
  | "profileImage"
  | "featuredMedia"
  | "companyDetails"
  | "contact"
  | "openingHours"
  | "shipping";

export type CurrentStepProps = {
  stepId: FormEditStoreStepIds;
  onContinue: (
    stepId: FormEditStoreStepIds,
    visibleFields?: Array<keyof Inputs>
  ) => void;
  isSubmitting?: boolean;
  form: UseFormReturn<Inputs>;
  store: StrapiStore;
};

export type SideMenuItem = {
  icon: IconType;
  sideMenuTitle: string;
  preview: PreviewElement[];
  submitButtonEnabled?: boolean;
  CurrentStep: React.FC<CurrentStepProps>;
};

export const getSideMenuItems = () => {
  return [
    {
      id: "storeDescription",
      status: "pending",
      active: true,
      payload: {
        icon: "feather",
        sideMenuTitle: "Beskrivelse",
        preview: descriptionPreviews,
        CurrentStep: (props: CurrentStepProps) => (
          <DescriptionStep {...props} />
        ),
      },
    },
    {
      id: "profileImage",
      status: "pending",
      active: false,
      payload: {
        icon: "personSquare",
        sideMenuTitle: "Profilbillede",
        preview: profileImagePreviews,
        CurrentStep: (props: CurrentStepProps) => (
          <ProfileImageStep {...props} />
        ),
      },
    },
    {
      id: "featuredMedia",
      status: "pending",
      active: false,
      payload: {
        icon: "images",
        sideMenuTitle: "Bannerbillede/video",
        preview: featuredImagePreviews,
        CurrentStep: (props: CurrentStepProps) => (
          <FeaturedImageStep {...props} />
        ),
        submitButtonEnabled: true,
      },
    },
    {
      id: "companyDetails",
      status: "pending",
      active: false,
      payload: {
        icon: "briefcase",
        sideMenuTitle: "Virksomhed",
        preview: companyDetailsPreviews,
        CurrentStep: (props: CurrentStepProps) => (
          <CompanyDetailsStep {...props} />
        ),
      },
    },
    {
      id: "contact",
      status: "pending",
      active: false,
      payload: {
        icon: "messages",
        sideMenuTitle: "Kontakt",
        preview: contactPreviews,
        CurrentStep: (props: CurrentStepProps) => <ContactStep {...props} />,
      },
    },

    {
      id: "openingHours",
      status: "pending",
      active: false,
      payload: {
        icon: "clock",
        sideMenuTitle: "Åbningstider",
        preview: openingHoursPreviews,
        CurrentStep: (props: CurrentStepProps) => (
          <OpeningHoursStep {...props} />
        ),
        submitButtonEnabled: true,
      },
    },
    {
      id: "shipping",
      status: "pending",
      active: false,
      payload: {
        icon: "shippingFront",
        sideMenuTitle: "Fragt",
        preview: shippingPreviews,
        CurrentStep: (props: CurrentStepProps) => <ShippingStep {...props} />,
      },
    },
  ] as Step<SideMenuItem, FormEditStoreStepIds>[];
};
