import { UseFormReturn } from "react-hook-form";
import { Inputs } from "../useStoreForm";
import { ShipmondoInputs } from "./ShipmondoInputs";
import { SelfManagedInputs } from "./SelfManagedInputs";

type Props = { form: UseFormReturn<Inputs> };

export const DeliveryInputs = ({ form }: Props) => {
  const { watch } = form;

  const deliveryType = watch("deliveryType");

  if (deliveryType === "shipmondo") return <ShipmondoInputs form={form} />;

  if (deliveryType === "selfManaged") return <SelfManagedInputs form={form} />;

  return null;
};
