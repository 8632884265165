/* eslint-disable camelcase */

import { getStripe } from "~/utils/stripe";
import { showToast } from "~/utils/toast";
import { Inputs } from "./useStoreForm";
import { AxiosError } from "axios";
import { phone } from "phone";
import { getWebsiteUrl } from "~/utils/getWebsiteUrl";
import { FormattedValues } from "./formatValues";
import { handleError } from "~/utils/rollbar";
import { StrapiResponse } from "~/shared-types";
import { strapiClient } from "~/strapi/api";

type AxiosErrorData = {
  name: keyof Inputs;
  message: string;
};

const getError = (input: keyof Inputs) => ({
  error: {
    name: input,
    message: "Feltet er ikke udfyldt korrekt",
  },
});

export const createStripeAccountFromFormData = async (
  formData: FormattedValues
): Promise<
  | { accountId: string }
  | { error: { name: keyof Inputs | null; message?: string } }
> => {
  if (!formData.storeId) throw new Error("Butiks-ID mangler");

  if (!formData.bankAccountIban) return getError("bankAccountIban");
  if (!formData.bankAccountSwiftBic) return getError("bankAccountSwiftBic");
  if (!formData.bankAccountFirstName) return getError("bankAccountFirstName");
  if (!formData.bankAccountLastName) return getError("bankAccountLastName");
  if (!formData.ownerBirthday) return getError("ownerBirthday");
  if (!formData.ownerEmail) return getError("ownerEmail");
  if (!formData.ownerFirstName) return getError("ownerFirstName");
  if (!formData.ownerLastName) return getError("ownerLastName");
  if (!formData.ownerPhone) return getError("ownerPhone");
  if (!formData.companyAddress?.city) return getError("companyAddress");
  if (!formData.companyAddress?.line1) return getError("companyAddress");
  if (!formData.companyAddress?.postCode) return getError("companyAddress");
  if (!formData.cvr) return getError("cvr");
  if (!formData.name) return getError("name");

  try {
    const stripe = await getStripe();
    if (!stripe) {
      return {
        error: {
          name: "bankAccountIban",
          message: "Stripe er ikke tilgængelig",
        },
      };
    }

    const { token, error } = await stripe.createToken("bank_account", {
      country: "DK",
      currency: "dkk",
      routing_number: formData.bankAccountSwiftBic,
      account_number: formData.bankAccountIban,
      account_holder_name: `${formData.bankAccountFirstName} ${formData.bankAccountLastName}`,
      account_holder_type: "individual",
    });

    if (!token) {
      if (error.param === "bank_account[account_number]") {
        return { error: { name: "bankAccountIban", message: error.message } };
      }

      return {
        error: { name: "bankAccountSwiftBic", message: error.message },
      };
    }

    const birthday = new Date(formData.ownerBirthday);

    const storeAddressObject = formData.sameAddress
      ? {
          line1: formData.companyAddress?.line1,
          line2: formData.companyAddress?.line2,
          city: formData.companyAddress?.city,
          postalCode: formData.companyAddress?.postCode,
        }
      : {
          line1: formData.shownAddress?.line1,
          line2: formData.shownAddress?.line2,
          city: formData.shownAddress?.city,
          postalCode: formData.shownAddress?.postCode,
        };

    const { data } = await strapiClient.post<
      StrapiResponse<{ accountId: string }>
    >(`/stores/stripe-account/${formData.storeId}`, {
      email: formData.ownerEmail,
      storeName: formData.name,
      address: storeAddressObject,
      phone: phone(formData.ownerPhone, {
        country: "DK",
        validateMobilePrefix: false,
      }).phoneNumber,
      vatId: formData.cvr,
      website: `${getWebsiteUrl()}/butikker/${formData.storeId}`,

      bankAccountToken: token?.id,

      person: {
        firstName: formData.ownerFirstName,
        lastName: formData.ownerLastName,
        email: formData.ownerEmail,
        jobTitle: "Owner",
        dateOfBirth: {
          day: birthday.getDate(),
          month: birthday.getMonth() + 1,
          year: birthday.getFullYear(),
        },
        address: {
          city: formData.companyAddress?.city,
          line1: formData.companyAddress?.line1,
          line2: formData.companyAddress?.line2,
          postalCode: formData.companyAddress?.postCode,
        },
        phone: phone(formData.ownerPhone, {
          country: "DK",
          validateMobilePrefix: false,
        }).phoneNumber,
      },
    });

    return { accountId: data.data.accountId };
  } catch (error) {
    const axiosErrorData = (error as AxiosError).response?.data as
      | AxiosErrorData
      | undefined;

    showToast("danger", "Noget gik galt.", "Prøv igen senere.");
    handleError(error as Error);

    if (
      typeof axiosErrorData === "object" &&
      "name" in axiosErrorData &&
      "message" in axiosErrorData
    ) {
      const { name, message } = axiosErrorData;
      return { error: { name, message } };
    }

    return { error: { name: null, message: "Noget gik galt" } };
  }
};
