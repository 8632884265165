import { A11y, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Image from "next/image";

import "swiper/swiper.min.css";
import "swiper/css/a11y";
import Icon from "~/assets/icons/Icon";
import classNames from "classnames";

type PreviewProps = {
  elements?: PreviewElement[];
};
export type PreviewElement = {
  id: string | number;
  title: string;
  src: string;
  alt: string;
  width: number;
  height: number;
};

const navigation = {
  prevEl: ".slider-left-chevron",
  nextEl: ".slider-right-chevron",
  disabledClass:
    "text-secondary-300 hover:text-secondary-300 hover:bg-transparent",
};

export const createPreviewObjects = (
  parentId: string,
  paths: PreviewElement[]
) =>
  paths.map(({ id, src, title, alt, width, height }) => ({
    id: `${parentId}-${id}`,
    title,
    src: `/assets/images/previews/store/${src}`,
    alt,
    width,
    height,
  }));

export function Preview({ elements }: PreviewProps) {
  if (elements?.length === 0) return null;
  const element = elements?.length === 1 ? elements?.[0] : null;
  return (
    <div className="relative max-w-[240px] xs:max-w-xs">
      {elements?.length === 1 ? null : (
        <>
          <SliderLeftButton />
          <SliderRightButton />
        </>
      )}
      {element === null ? (
        <Swiper
          modules={[A11y, Pagination, Navigation]}
          spaceBetween={20}
          slidesPerView={1.0}
          navigation={navigation}
          pagination={{
            el: ".swiper-dots",
            clickable: true,
            bulletClass: "bg-secondary-200",
            bulletActiveClass: "bg-secondary-800",
            // TODO: Fix this with tests?
            // istanbul ignore next
            renderBullet(_, className) {
              // istanbul ignore next
              return `<span class="${className} h-3 w-3 rounded-full cursor-pointer"></span>`;
            },
          }}
        >
          {elements?.map((element) => (
            <SwiperSlide key={element.id} className="px-1">
              <p className="text-center text-xs text-secondary-600 mb-7">
                {element.title}
              </p>
              <Image
                src={element.src}
                alt={element.alt}
                width={element.width}
                height={element.height}
                className="mx-auto shadow-md"
              />
            </SwiperSlide>
          ))}
          <div className="swiper-dots flex justify-center gap-3 mt-5" />
        </Swiper>
      ) : (
        <>
          {element.title && (
            <p className="text-center text-xs text-secondary-600 mb-7">
              {element.title}
            </p>
          )}
          <Image
            src={element.src}
            alt={element.alt}
            width={element.width}
            height={element.height}
          />
        </>
      )}
    </div>
  );
}

const sliderButtonSharedClassName =
  "w-12 h-12 absolute top-1/2 -translate-y-1/2 z-10 hidden sm:flex justify-center items-center rounded-full border-none hover:bg-secondary-200 transition-colors duration-200 ease-in-out";
function SliderLeftButton() {
  return (
    <button
      className={classNames(
        "slider-left-chevron left-0 translate-x-[calc(-100%-2rem)] lg:translate-x-[calc(-100%+8px)] xl:translate-x-[calc(-100%-8px)]",
        sliderButtonSharedClassName
      )}
      aria-label="Forrige billede"
    >
      <Icon
        name="chevronRight"
        title="Skift til næste"
        className="rotate-180 w-6 h-6"
      />
    </button>
  );
}
function SliderRightButton() {
  return (
    <button
      className={classNames(
        "slider-right-chevron right-0 translate-x-[calc(100%+2rem)] lg:translate-x-[calc(100%-8px)] xl:translate-x-[calc(100%+8px)]",
        sliderButtonSharedClassName
      )}
      aria-label="Næste billede"
    >
      <Icon name="chevronRight" title="Skift til forrige" className="w-6 h-6" />
    </button>
  );
}
