/* istanbul ignore file */

import { useState } from "react";
import Icon from "~/assets/icons/Icon";
import OpenHours from "~/components/Backroom/OpenHours";
import { OpeningHourObject } from "~/shared-types";

type OpeningHoursInputsProps = {
  hours: OpeningHourObject["hours"];
  onUpdate: (hours: OpeningHourObject["hours"]) => void;
};

const getEmptyTime = (id = 1) => ({ id, from: "", to: "" });

export const OpeningHoursInputs = ({
  hours,
  onUpdate,
}: OpeningHoursInputsProps) => {
  const [array, setArray] = useState(
    hours && hours.length > 0 ? hours : [getEmptyTime()]
  );

  const highestId = array.reduce((id, hour) => {
    if (hour.id > id) return hour.id;
    return id;
  }, 0);

  const onAddTime = (index: number) => {
    setArray((current) => {
      const newArray = [...current];
      newArray.splice(index + 1, 0, getEmptyTime(highestId + 1));
      return newArray;
    });
  };

  const onRemoveTime = (index: number) => {
    setArray((current) => {
      const newArray = [...current];
      newArray.splice(index, 1);
      return newArray;
    });
  };

  const onRowUpdate = (key: "to" | "from", value: string, index: number) => {
    setArray((current) => {
      const newArray = [...current];
      newArray[index][key] = value;
      return newArray;
    });

    onUpdate(array);
  };

  return (
    <>
      {array.map((openingHour, index) => (
        <div
          key={openingHour.id}
          className="min-[560px]:flex items-center gap-4 mb-5 min-[560px]:mb-0"
        >
          <OpenHours
            id={openingHour.id.toString()}
            toValue={openingHour.to}
            fromValue={openingHour.from}
            onFrom={(value: string) => onRowUpdate("from", value, index)}
            onTo={(value: string) => onRowUpdate("to", value, index)}
          />
          <div className="flex gap-2 lg:gap-4 justify-end mr-3 min-[560px]:mr-0">
            <button
              type="button"
              onClick={() => onAddTime(index)}
              className="hover:bg-black/5 w-10 h-10 rounded-full flex items-center justify-center"
              aria-label="Tilføj række"
            >
              <Icon name="plus" className="w-6 h-6" />
            </button>
            {index > 0 ? (
              <button
                type="button"
                onClick={() => onRemoveTime(index)}
                className="hover:bg-black/5 w-10 h-10 rounded-full flex items-center justify-center"
                aria-label="Fjern række"
              >
                <Icon name="trash" className="w-6 h-6" />
              </button>
            ) : null}
          </div>
        </div>
      ))}
    </>
  );
};
