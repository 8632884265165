import { Inputs } from "./useStoreForm";

export type FormattedValues = ReturnType<typeof formatValues>;

export const formatValues = (
  inputs: Inputs,
  visibleFields?: Array<keyof Inputs>
) => {
  const formData = {
    ...inputs,
    acceptsPickUp: inputs.acceptsPickUp === "yes",
    sameAddress: inputs.sameAddress === "yes",
    showAddressOnMap: inputs.showAddressOnMap === "yes",
    offersFreeDelivery: inputs.offersFreeDelivery === "yes",

    deliveryPrice: inputs.deliveryPrice || 0,
    freeDeliveryPurchasePrice: inputs.freeDeliveryPurchasePrice || undefined,
  };

  if (formData.sameAddress) {
    formData.shownAddress = formData.companyAddress;
  }

  if (visibleFields) {
    Object.keys(formData).forEach((key) => {
      if (!visibleFields.includes(key as keyof Inputs)) {
        delete formData[key as keyof Inputs];
      }
    });
  }

  return formData;
};
