import { FieldError, UseFormRegister, RegisterOptions } from "react-hook-form";
import { UncontrolledTextarea } from "../UncontrolledTextarea";

type TextareaProps = {
  id: string;
  label: string;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
  registerOptions?: RegisterOptions;
  error?: FieldError | undefined;
  maxLength?: number;
  rows?: number;
  initialValue?: string;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

export const Textarea = (props: TextareaProps) => {
  const { id, register, registerOptions, ...restProps } = props;

  const { ref, ...registerProps } = register(id, registerOptions);

  return (
    <UncontrolledTextarea
      ref={ref}
      id={id}
      registerOptions={registerOptions}
      {...restProps}
      {...registerProps}
    />
  );
};
