import React from "react";
import {
  UseFormRegister,
  RegisterOptions,
  ChangeHandler,
} from "react-hook-form";
import UncontrolledInput, {
  UncontrolledInputProps,
} from "../UncontrolledInput";
import phone from "phone";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
  registerOptions?: RegisterOptions;
} & UncontrolledInputProps;

export const validatePhoneNumber = (value: string) => {
  if (
    !phone(value, {
      country: "DK",
      validateMobilePrefix: false,
    }).isValid
  )
    return "Venligst indtast et korrekt dansk telefonnummer";
};

const PhoneInput = (props: Props) => {
  const { id, register, registerOptions, ...restProps } = props;

  const phoneRegisterOptions: RegisterOptions | undefined = registerOptions
    ? {
        ...registerOptions,
        validate: validatePhoneNumber,
      }
    : { validate: validatePhoneNumber };

  const { ref, ...registerProps } = register(id, phoneRegisterOptions);

  const onChange: ChangeHandler = async (event) => {
    event.target.value = event.target.value.trim();
    registerProps.onChange(event);
  };

  return (
    <UncontrolledInput
      ref={ref}
      id={id}
      registerOptions={phoneRegisterOptions}
      {...restProps}
      {...registerProps}
      onChange={onChange}
    />
  );
};

export default PhoneInput;
