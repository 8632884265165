import { UseFormReturn } from "react-hook-form";
import { Inputs } from "../useStoreForm";
import { InfoBox } from "../../InfoBox";

type Props = { form: UseFormReturn<Inputs> };

export const DeliveryInfoBox = ({ form }: Props) => {
  const { watch } = form;

  const deliveryType = watch("deliveryType");

  if (deliveryType === "selfManaged") {
    return (
      <InfoBox>
        <ul className="list-disc pl-6">
          <li>
            Standard fragtpris bliver pålagt ordren og ikke det enkelte produkt.
          </li>
          <li>
            Det er muligt at tilføje en ekstra fragtpris for specielle varer,
            når du opretter eller redigere dine produkter.
          </li>
        </ul>
      </InfoBox>
    );
  }

  if (deliveryType === "shipmondo") {
    return (
      <InfoBox>
        Med din Shipmondo-konto har du adgang til alle Shipmondo's fordele:
        <ul className="list-disc pl-6">
          <li>Intet abonnement</li>
          <li>Alle integrationer er gratis</li>
          <li>Gratis support</li>
          <li>Betal kun for forbrug</li>
          <li>Alle features fra dag ét</li>
        </ul>
      </InfoBox>
    );
  }

  return null;
};
