/* istanbul ignore file */

import { Controller } from "react-hook-form";
import { InfoBox } from "~/components/Backroom/InfoBox";
import { Inputs } from "../useStoreForm";
import { createPreviewObjects } from "~/components/Backroom/Preview";
import { FileUpload } from "~/components/Backroom/FileUpload";
import { CurrentStepProps } from "../getSideMenuItems";
import { ContinueButton } from "../ContinueButton";

export const previewObjects = createPreviewObjects("profileImage", [
  {
    id: 1,
    title: "Placering af profilbillede på butikssiden",
    src: "store-image/desktop.png",
    alt: "Placering af profilbillede på butikssiden",
    width: 340,
    height: 420,
  },
  {
    id: 2,
    title: "Placering af profilbillede på butikssiden (mobil)",
    src: "store-image/mobil.png",
    alt: "Placering af profilbillede på butikssiden (mobil)",
    width: 200,
    height: 500,
  },
  {
    id: 3,
    title: "Placering af profilbillede på kortvisning",
    src: "store-image/desktop2.png",
    alt: "Placering af profilbillede på kortvisning",
    width: 340,
    height: 420,
  },
  {
    id: 4,
    title: "Placering af profilbillede på kortvisning (mobil)",
    src: "store-image/mobil2.png",
    alt: "Placering af profilbillede på kortvisning (mobil)",
    width: 200,
    height: 500,
  },
  {
    id: 5,
    title: "Placering af profilbillede på produktsiden",
    src: "store-image/desktop3.png",
    alt: "Placering af profilbillede på produktsiden",
    width: 340,
    height: 420,
  },
  {
    id: 6,
    title: "Placering af profilbillede på produktsiden (mobil)",
    src: "store-image/mobil3.png",
    alt: "Placering af profilbillede på produktsiden (mobil)",
    width: 200,
    height: 500,
  },
]);

export const FIELDS: Array<keyof Inputs> = ["storeImage"];

export const ProfileImageStep = (props: CurrentStepProps) => {
  const {
    stepId,
    form: { control, trigger, setError, watch },
    isSubmitting,
    onContinue,
  } = props;
  const storeImage = watch("storeImage");

  const beforeContinue = async () => {
    const isValid = await trigger(FIELDS);

    if (storeImage === null) {
      setError("storeImage", { message: "Du skal uploade et billede" });
      return;
    }

    if (isValid) onContinue(stepId, FIELDS);
  };

  return (
    <>
      <h1 className="text-3xl mb-5">Butikkens profilbillede</h1>
      <p className="mb-12">
        Dette foto er din butiks profilbillede. Det vil typisk være relativt
        småt, så brug gerne et simpelt billede uden for mange elementer.
      </p>

      <div className="mb-12">
        <Controller
          control={control}
          name="storeImage"
          render={({
            field: { value, onChange, ...field },
            fieldState: { error },
          }) => {
            let initialFiles = value ? [value] : [];
            if (Array.isArray(value)) {
              initialFiles = value;
            }

            return (
              <div className="mb-12">
                <FileUpload
                  id="storeImage"
                  label="Upload billede:"
                  required
                  {...field}
                  initialFiles={initialFiles}
                  onChange={onChange}
                  acceptedFileTypes={["image/*"]}
                  browseText="Tilføj billedfil for at uploade eller"
                  error={error}
                  // acceptedOrientations={["square"]}
                  aspectRatioClass="aspect-square"
                />
              </div>
            );
          }}
        />
      </div>

      <div className="flex flex-col-reverse lg:flex-col gap-12">
        <InfoBox>
          <ul className="list-disc pl-6">
            <li>Vælg gerne et foto med mennesker på.</li>
            <li>
              Fotoet skal være kvadratisk og gerne mindst 800 x 800 pixels.
            </li>
          </ul>
        </InfoBox>
        <ContinueButton
          beforeContinue={beforeContinue}
          isSubmitting={isSubmitting}
        />
      </div>
    </>
  );
};
