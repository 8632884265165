import React from "react";
import { Dialog } from "../Dialog";
import QuestionmarkIcon from "../QuestionmarkIcon";
import { ScrollArea } from "../layout/ScrollArea";
import Button from "~/components/Button";
import Icon from "~/assets/icons/Icon";

const InfoModal = ({
  id,
  label,
  children,
  htmlFor,
}: {
  id: string;
  label: string;
  children: React.ReactNode;
  htmlFor?: string;
}) => {
  return (
    <div className="flex items-center gap-2.5">
      <label htmlFor={htmlFor} id={id}>
        {label}
      </label>
      <Dialog
        trigger={
          <button
            type="button"
            className="rounded-full text-white bg-secondary-900"
          >
            <QuestionmarkIcon />
          </button>
        }
        className="grid p-20 pb-8"
        displayIcon
      >
        <ScrollArea>
          {children}
          <Dialog.Close>
            <Button
              variant="primary-light"
              size="lg"
              className="mx-auto flex items-center gap-2 mt-8"
            >
              Luk
              <Icon name="close" className="w-6 h-6" />
            </Button>
          </Dialog.Close>
        </ScrollArea>
      </Dialog>
    </div>
  );
};

export default InfoModal;
