import { UseFormReturn } from "react-hook-form";
import { Inputs } from "../useStoreForm";
import InfoModal from "../../Infomodal";
import Input from "../../Input";
import { RadioGroup } from "../../RadioGroup";
import { stringToCurrency } from "~/utils/stringConversion";

type Props = { form: UseFormReturn<Inputs>; className?: string };

export const FreeDeliveryInputs = ({ form, className }: Props) => {
  const { register, formState, watch, control } = form;

  const offersFreeDelivery = watch("offersFreeDelivery");
  return (
    <div className={className}>
      <div className="flex flex-col gap-y-1.5 mb-12">
        <InfoModal
          id="freeDeliveryDescription"
          label="Tilbyder du fri fragt ved køb over en given pris?"
          htmlFor="offersFreeDelivery"
        >
          Hvis du vælger 'Ja', skal du udfylde minimum total pris for fri fragt
          i boksen nedenunder.
        </InfoModal>

        <RadioGroup
          id="offersFreeDelivery"
          aria-describedby="freeDeliveryDescription"
          control={control}
        >
          <RadioGroup.Item value="yes">Ja</RadioGroup.Item>
          <RadioGroup.Item value="no">Nej</RadioGroup.Item>
        </RadioGroup>
      </div>

      {offersFreeDelivery === "yes" ? (
        <Input
          id="freeDeliveryPurchasePrice"
          label="Minimum total pris for fri fragt:"
          placeholder="500,00"
          type="currency"
          register={register}
          className="mb-12"
          registerOptions={{
            valueAsNumber: true,
            required: "Pris for fri fragt skal udfyldes",
            max: {
              value: 999999,
              message: `Pris kan ikke være højere end ${stringToCurrency({
                amount: 999999,
              })}`,
            },
          }}
          error={formState.errors.freeDeliveryPurchasePrice}
        />
      ) : null}
    </div>
  );
};
