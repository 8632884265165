import {
  Content,
  Header,
  Item,
  Root,
  Trigger,
} from "@radix-ui/react-accordion";
import classNames from "classnames";
import { forwardRef } from "react";
import Icon from "~/assets/icons/Icon";
import {
  CompoundComponentContext,
  useCompoundComponent,
} from "~/hooks/useCompoundComponent";

type AccordionProps = {
  children: React.ReactNode;
  className?: string;
};
type AccordionItemProps = {
  children: React.ReactNode;
  className?: string;
} & React.ComponentProps<typeof Item>;
type AccordionTriggerProps = {
  children: React.ReactNode;
  className?: string;
} & React.ComponentProps<typeof Trigger>;
type AccordionContentProps = {
  children: React.ReactNode;
  className?: string;
} & React.ComponentProps<typeof Content>;

export function Accordion({ children, className }: AccordionProps) {
  return (
    <Root className={className} type="single" collapsible>
      <CompoundComponentContext.Provider value={"Accordion"}>
        {children}
      </CompoundComponentContext.Provider>
    </Root>
  );
}

Accordion.Item = forwardRef<HTMLDivElement, AccordionItemProps>(
  ({ children, className, ...props }: AccordionItemProps, forwardedRef) => {
    useCompoundComponent("Accordion", "Item");
    return (
      <Item
        className={classNames(
          "bg-secondary-100 last:mb-0 rounded-lg overflow-hidden first:mt-0 focus-within:relative focus-within:z-10 focus-within:shadow-[0_0_0_2px] focus-within:shadow-primary-500",
          className
        )}
        {...props}
        ref={forwardedRef}
      >
        {children}
      </Item>
    );
  }
);

Accordion.Trigger = forwardRef<HTMLButtonElement, AccordionTriggerProps>(
  ({ children, className, ...props }: AccordionTriggerProps, forwardedRef) => {
    useCompoundComponent("Accordion", "Trigger");
    return (
      <Header className="flex">
        <Trigger
          className={classNames(
            "group grid grid-cols-[1fr_1.5rem] gap-3 w-full cursor-default items-center p-5 text-xs text-secondary-600 outline-none",
            className
          )}
          {...props}
          ref={forwardedRef}
        >
          {children}
          <Icon
            name="chevronDown"
            className="w-6 h-6 transition-transform duration-150 group-data-[state=open]:rotate-180 shrink-0"
          />
        </Trigger>
      </Header>
    );
  }
);

Accordion.Content = forwardRef<HTMLDivElement, AccordionContentProps>(
  ({ children, className, ...props }: AccordionContentProps, forwardedRef) => {
    useCompoundComponent("Accordion", "Content");
    return (
      <Content
        className={classNames(
          "data-[state=open]:animate-accordionSlideDown data-[state=closed]:animate-accordionSlideUp overflow-hidden",
          className
        )}
        {...props}
        ref={forwardedRef}
      >
        <div className="pb-5 px-5">{children}</div>
      </Content>
    );
  }
);
