import classNames from "classnames";
import { forwardRef, useState } from "react";
import {
  FieldError,
  UseFormRegisterReturn,
  RefCallBack,
  RegisterOptions,
} from "react-hook-form";
import { InputError, ariaControlledBorder } from "../InputError";

type TextareaProps = {
  id: string;
  label: string;
  className?: string;
  error?: FieldError | undefined;
  maxLength?: number;
  rows?: number;
  initialValue?: string;
  registerOptions?: RegisterOptions;
  asRow?: boolean;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement> &
  Partial<UseFormRegisterReturn>;

export const UncontrolledTextarea = forwardRef(
  (
    {
      id,
      label,
      className,
      maxLength,
      rows = 8,
      onChange,
      initialValue,
      error,
      registerOptions,
      asRow,
      ...restProps
    }: TextareaProps,
    ref
  ) => {
    const [value, setValue] = useState<string>(
      restProps.defaultValue?.toString() ?? initialValue ?? ""
    );

    const beforeChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setValue(e.target.value);
      onChange?.(e);
    };

    return (
      <div
        className={classNames(asRow && "grid grid-cols-[24px_1fr]", className)}
      >
        <label htmlFor={id} className="text-secondary-600 text-xs">
          {registerOptions?.required ? label + "*" : label}
        </label>
        <div className="relative flex flex-col">
          <textarea
            id={id}
            ref={ref as RefCallBack}
            className={classNames(
              "text-secondary-800 placeholder:text-secondary-400 border border-secondary-200 px-5 pt-3 pb-8 bg-transparent rounded-lg",
              ariaControlledBorder
            )}
            autoFocus={Boolean(error)}
            aria-invalid={Boolean(error)}
            rows={rows}
            maxLength={maxLength}
            onChange={beforeChange}
            defaultValue={value}
            {...restProps}
          />
          {maxLength ? (
            <span className="absolute right-5 bottom-3 text-xs text-secondary-600">
              {value.length}/{maxLength}
            </span>
          ) : null}
        </div>
        <InputError>{error?.message}</InputError>
      </div>
    );
  }
);
