/* istanbul ignore file */

import { Controller } from "react-hook-form";
import { Inputs } from "../useStoreForm";
import { createPreviewObjects } from "~/components/Backroom/Preview";
import { InfoBox } from "~/components/Backroom/InfoBox";
import { FileUpload } from "~/components/Backroom/FileUpload";
import { CurrentStepProps } from "../getSideMenuItems";
import { ContinueButton } from "../ContinueButton";

export const previewObjects = createPreviewObjects("featuredImageStep", [
  {
    id: 1,
    title: "Placering af bannerbillede/video på butikssiden",
    src: "featured-media/desktop.png",
    alt: "Placering af bannerbillede/video på butikssiden",
    width: 340,
    height: 420,
  },
  {
    id: 2,
    title: "Placering af bannerbillede/video på butikssiden (mobil)",
    src: "featured-media/mobil.png",
    alt: "Placering af bannerbillede/video på butikssiden (mobil)",
    width: 200,
    height: 500,
  },
]);

export const FIELDS: Array<keyof Inputs> = ["featuredMedia", "coverVideo"];

export const FeaturedImageStep = ({
  form,
  onContinue,
  stepId,
  isSubmitting,
}: CurrentStepProps) => {
  const { control, watch, setError } = form;

  const featuredMedia = watch("featuredMedia");

  const beforeContinue = async () => {
    const isValid = await form.trigger(FIELDS);

    if (featuredMedia === null) {
      setError("featuredMedia", {
        message: "Du skal uploade et billede",
      });
      return;
    }

    if (isValid) onContinue(stepId, FIELDS);
  };

  return (
    <>
      <h1 className="text-3xl mb-5">Bannerbillede og -video</h1>
      <p className="mb-12">
        Her kan du både uploade et bannerbillede, samt en video. Såfremt der er
        en video, vil denne blive brugt, og billedet vil i så fald primært blive
        brugt som backup. Dette banner kunne f.eks. være et panorama af din
        butik/dine produkter, eller af medarbejderne.
      </p>

      <Controller
        control={control}
        name="featuredMedia"
        render={({
          field: { value, onChange, ...field },
          fieldState: { error },
        }) => {
          let initialFiles = value ? [value] : [];
          if (Array.isArray(value)) {
            initialFiles = value;
          }
          return (
            <div className="mb-12">
              <FileUpload
                id="featuredMedia"
                label="Upload billede:"
                required
                {...field}
                initialFiles={initialFiles}
                onChange={onChange}
                compact
                acceptedFileTypes={["image/*"]}
                browseText="Tilføj billedfil for at uploade eller"
                error={error}
                acceptedOrientations={["landscape"]}
                aspectRatioClass="aspect-[2/1]"
              />
            </div>
          );
        }}
      />

      <Controller
        control={control}
        name="coverVideo"
        render={({ field: { value, onChange, ...field } }) => {
          let initialFiles = value ? [value] : [];
          if (Array.isArray(value)) {
            initialFiles = value;
          }
          return (
            <div className="mb-12">
              <FileUpload
                id="coverVideo"
                label="Upload video"
                {...field}
                initialFiles={initialFiles}
                onChange={onChange}
                compact
                acceptedFileTypes={["video/*"]}
                browseText="Tilføj videofil for at uploade eller"
                acceptedOrientations={["landscape"]}
                aspectRatioClass="aspect-[2/1]"
              />
            </div>
          );
        }}
      />

      <div className="flex flex-col-reverse lg:flex-col gap-12">
        <InfoBox>
          <ul className="list-disc pl-6">
            <li>
              Vælg f.eks. et foto/video af alle medarbejdere eller butikken.
            </li>
            <li>Undlad at bruge et logo.</li>
            <li>
              Fotoet og videoen bliver vist i 2:1 format, dobbelt så bredt som
              det er højt.
            </li>
            <li>
              Videoen bør maksimum fylde 5mb, da det vil holde din butiksside
              hurtig. Du kan eventuelt finde et online værktøj til at komprimere
              videoen.
            </li>
          </ul>
        </InfoBox>
        <ContinueButton
          beforeContinue={beforeContinue}
          isSubmitting={isSubmitting}
        />
      </div>
    </>
  );
};
