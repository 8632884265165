import { useMemo } from "react";
import Input, { InputProps } from "../Input";

type Props = InputProps;

const isValidEmail = (value: string) =>
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    value
  );

export const EmailInput = ({ registerOptions, ...restProps }: Props) => {
  const combinedRegisterOptions = useMemo(() => {
    if (registerOptions?.validate) {
      return registerOptions;
    }

    return {
      ...registerOptions,
      validate: (value: string) => {
        if (!isValidEmail(value)) {
          return "Venligst indtast korrekt email adresse";
        }
      },
    };
  }, []);

  return <Input registerOptions={combinedRegisterOptions} {...restProps} />;
};
