import React from "react";
import classNames from "classnames";
import UncontrolledInput from "../UncontrolledInput";

type OpenHoursType = {
  id: string;
  className?: string;
  fromValue?: string;
  toValue?: string;
  onFrom?: (value: string) => void;
  onTo?: (value: string) => void;
};

const fixedClassName = "items-center gap-2 text-base mb-1.5 min-[560px]:mb-0";

const OpenHours = ({
  id,
  className,
  fromValue,
  toValue,
  onFrom,
  onTo,
}: OpenHoursType) => {
  return (
    <div className="min-[560px]:flex gap-5 ml-9">
      <UncontrolledInput
        id={`${id}-from`}
        label={"Fra:"}
        placeholder={"10:00"}
        type="time"
        className={classNames(fixedClassName, className)}
        initialValue={fromValue}
        onBlur={async (e) => {
          onFrom?.(e.target.value);
        }}
        asRow
      />
      <UncontrolledInput
        id={`${id}-to`}
        label={"Til:"}
        placeholder={"16:00"}
        type="time"
        className={classNames(fixedClassName, className)}
        initialValue={toValue}
        onBlur={async (e) => {
          onTo?.(e.target.value);
        }}
        asRow
      />
    </div>
  );
};

export default OpenHours;
