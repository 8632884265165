import { getGoogleMapsApiClient } from "../getGoogleMapsApiClient";

export const getAutocompleteSessionToken = async () => {
  const googleApiClient = await getGoogleMapsApiClient();
  if (!googleApiClient) {
    return;
  }

  const sessionToken = new googleApiClient.AutocompleteSessionToken();

  return sessionToken;
};

export const getPlacePredictions = async (
  inputValue: string,
  sessionToken: google.maps.places.AutocompleteSessionToken
) => {
  const googleApiClient = await getGoogleMapsApiClient();
  if (!googleApiClient) {
    return { predictions: [], status: "UNKNOWN_ERROR" as const };
  }

  const autocompleteService = new googleApiClient.AutocompleteService();
  return await new Promise<{
    predictions: google.maps.places.AutocompletePrediction[];
    status: google.maps.places.PlacesServiceStatus;
  }>((resolve) => {
    autocompleteService.getPlacePredictions(
      {
        input: inputValue,
        sessionToken,
        componentRestrictions: { country: "dk" },
      },
      (predictions, status) => {
        resolve({ predictions: predictions || [], status });
      }
    );
  });
};

export const getPlaceDetails = async (
  placeId: string,
  attributionContainer: HTMLDivElement,
  sessionToken?: google.maps.places.AutocompleteSessionToken
) => {
  const googleApiClient = await getGoogleMapsApiClient();
  if (!googleApiClient) {
    return { placeDetails: null, status: "UNKNOWN_ERROR" as const };
  }

  const placesService = new googleApiClient.PlacesService(attributionContainer);

  return await new Promise<{
    placeDetails: google.maps.places.PlaceResult | null;
    status: google.maps.places.PlacesServiceStatus;
  }>((resolve) => {
    placesService.getDetails(
      {
        placeId,
        fields: [
          "formatted_address",
          "geometry.location",
          "place_id",
          "address_components",
        ],
        sessionToken,
      },
      (placeDetails, status) => {
        resolve({ placeDetails, status });
      }
    );
  });
};

export const getAddressComponents = (
  place?: google.maps.places.PlaceResult | null
) => {
  if (!place?.address_components) return {};

  const address = place.address_components.find((component) =>
    component.types.includes("route")
  );
  const address2 = place.address_components.find((component) =>
    component.types.includes("subpremise")
  );
  const streetNumber = place.address_components.find((component) =>
    component.types.includes("street_number")
  );
  // For address where sublocality is missing, we will be using locality
  // ex. Kullinggade 10, Svendborg (locality)
  // Vesterbrogade 15, København V (sublocality)
  const city =
    place.address_components.find((component) =>
      component.types.includes("sublocality")
    ) ??
    place.address_components.find((component) =>
      component.types.includes("locality")
    );

  const postCode = place.address_components.find((component) =>
    component.types.includes("postal_code")
  );

  if (
    !address?.long_name ||
    !streetNumber?.long_name ||
    !city?.long_name ||
    !postCode?.long_name
  )
    return null;

  return {
    address: `${address.long_name} ${streetNumber.long_name}`,
    address2: address2?.long_name || "",
    city: city.long_name,
    postCode: postCode.long_name,
  };
};

export const formatAddress = ({
  address,
  address2,
  city,
  postCode,
}: {
  address?: string;
  address2?: string;
  city?: string;
  postCode?: string;
}) => {
  const addressLine = [address, address2].filter(Boolean).join(" ");

  return `${addressLine}, ${postCode} ${city}`;
};
