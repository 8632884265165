import { UseFormReturn } from "react-hook-form";
import Input from "../../Input";
import { Inputs } from "../useStoreForm";
import { stringToCurrency } from "~/utils/stringConversion";
import { FreeDeliveryInputs } from "./FreeDeliveryInputs";

type Props = {
  form: UseFormReturn<Inputs>;
};

export const SelfManagedInputs = ({ form }: Props) => {
  const { register, formState } = form;

  return (
    <>
      <Input
        id="deliveryPrice"
        label="Standard fragtpris:"
        placeholder="49,00"
        type="currency"
        register={register}
        className="mb-12"
        registerOptions={{
          valueAsNumber: true,
          required: "Fragtpris er påkrævet",
          max: {
            value: 999999,
            message: `Pris kan ikke være højere end ${stringToCurrency({
              amount: 999999,
            })}`,
          },
        }}
        error={formState.errors.deliveryPrice}
      />

      <FreeDeliveryInputs form={form} />
    </>
  );
};
