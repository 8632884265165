/* istanbul ignore file */

import { OpeningHourObject } from "~/shared-types";

type OpeningHoursHeaderProps = {
  dayName: string;
  openingHours?: OpeningHourObject;
};

const getTimeString = (from?: string, to?: string) => {
  if (!from && !to) return undefined;

  return `${from || "?"} - ${to || "?"}`;
};

export const OpeningHoursHeader = ({
  dayName,
  openingHours,
}: OpeningHoursHeaderProps) => {
  const { type, hours } = openingHours ?? {};

  let timeString = "Lukket";

  if (type === "open") {
    const times = hours?.map(({ from, to }) => getTimeString(from, to)) ?? [];
    timeString = times.filter(Boolean).join(", ") ?? [];
  }

  if (type === "openByAgreement") {
    const times = hours?.map(({ from, to }) => getTimeString(from, to)) ?? [];
    timeString = `Åben efter aftale ${times.join(", ")}`;
  }

  return (
    <span className="truncate whitespace-nowrap min-w-0 max-w-full text-left">
      {`${dayName}: ${timeString}`}
    </span>
  );
};
