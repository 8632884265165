import { Inputs } from "../useStoreForm";
import { RadioGroup } from "~/components/Backroom/RadioGroup";
import { createPreviewObjects } from "~/components/Backroom/Preview";
import { CurrentStepProps } from "../getSideMenuItems";
import { ContinueButton } from "../ContinueButton";
import { DeliveryInputs } from "./DeliveryInputs";
import { DeliveryInfoBox } from "./DeliveryInfoBox";
import { useEffect, useState } from "react";
import { AlertDialog } from "../../AlertDialog";

export const previewObjects = createPreviewObjects("shippingStep", [
  {
    id: 1,
    title: "Shipmondo fragt",
    src: "shipping/shipmondo-desktop.png",
    alt: "Shipmondo fragt",
    width: 1360,
    height: 1680,
  },
  {
    id: 2,
    title: "Shipmondo fragt (mobil)",
    src: "shipping/shipmondo-mobile.png",
    alt: "Shipmondo fragt (mobil)",
    width: 602,
    height: 1629,
  },
  {
    id: 3,
    title: "Manuel fragt",
    src: "shipping/self-managed-desktop.png",
    alt: "Manuel fragt",
    width: 1360,
    height: 1680,
  },
  {
    id: 4,
    title: "Manuel fragt (mobil)",
    src: "shipping/self-managed-mobile.png",
    alt: "Manuel fragt (mobil)",
    width: 600,
    height: 1500,
  },
]);

export const FIELDS: Array<keyof Inputs> = [
  "deliveryPrice",
  "offersFreeDelivery",
  "freeDeliveryPurchasePrice",
  "deliveryType",
  "shipmondoUsername",
  "shipmondoPassword",
];

export const ShippingStep = ({
  form,
  onContinue,
  stepId,
  isSubmitting,
  store,
}: CurrentStepProps) => {
  const { control, watch, setValue, formState } = form;
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const beforeContinue = async () => {
    const isValid = await form.trigger(FIELDS);

    if (isValid) onContinue(stepId, FIELDS);
  };

  const deliveryType = watch("deliveryType");

  useEffect(() => {
    if (
      store.attributes.publishedAt &&
      deliveryType === "shipmondo" &&
      formState.defaultValues?.deliveryType !== "shipmondo"
    ) {
      setOpenConfirmModal(true);
    }
  }, [deliveryType]);

  const onCancelShipmondo = () => {
    setValue(
      "deliveryType",
      formState.defaultValues?.deliveryType ?? "selfManaged"
    );
    setOpenConfirmModal(false);
  };

  const onConfirmShipmondo = () => {
    setOpenConfirmModal(false);
  };
  const acceptsPickUp = watch("acceptsPickUp");

  return (
    <>
      <h1 className="text-3xl mb-5">Fragt</h1>

      <div className="flex flex-col gap-y-1.5 mb-12">
        <label id="deliveryTypeLabel" className="mb-12">
          Vælg hvordan du ønsker at håndtere levering af dine varer. Du har
          altid mulighed for at ændre dit valg.
        </label>

        <RadioGroup
          id="deliveryType"
          aria-describedby="deliveryTypeLabel"
          control={control}
        >
          <RadioGroup.Item value="selfManaged">
            Jeg vil selv håndtere fragt
          </RadioGroup.Item>
          <RadioGroup.Item value="shipmondo">
            Fragt med Shipmondo, integreret i Butik Danmark (GLS, Bring og
            PostNord)
          </RadioGroup.Item>
          {acceptsPickUp === "yes" ? (
            <RadioGroup.Item value="none">
              <span>Ingen fragt</span>
            </RadioGroup.Item>
          ) : (
            <RadioGroup.Item value="none" disabled>
              <span>Ingen fragt</span>
            </RadioGroup.Item>
          )}
        </RadioGroup>
        {acceptsPickUp === "yes" ? null : (
          <span>
            OBS: Du kan ikke vælge optionen "ingen fragt", fordi butikken tager
            ikke imod kunder, på sin fysisk adresse.
          </span>
        )}
      </div>
      <DeliveryInputs form={form} />

      <div className="flex flex-col-reverse lg:flex-col gap-12">
        <DeliveryInfoBox form={form} />

        <ContinueButton
          beforeContinue={beforeContinue}
          isSubmitting={isSubmitting}
        />
      </div>

      <AlertDialog
        trigger={null}
        open={openConfirmModal}
        onOpenChange={setOpenConfirmModal}
      >
        <h4 className="text-secondary-800 m-0 text-xl font-medium mb-5 text-center">
          Automatiseret fragt med Shipmondo
        </h4>
        <p className="text-center mb-4">
          Vælger du at bruge Shipmondo, er det et krav at du noterer vægt på
          dine produkter
          <br />
          når du opretter eller redigere dem, så leveringsprisen kan beregnes.
        </p>
        <p className="text-center mb-4">
          Hvis du allerede har oprettet produkter uden vægt, vil de automatisk
          blive gjort til kladde
          <br />
          og kan først publiceres til butikken igen, når de er blevet opdateret
          med vægt.
        </p>
        <p className="text-center">
          Hvis du har haft Shipmondo tilkoblet tidligere, vil de nuværende
          produkter,
          <br />
          der var oprettet på daværende tidspunkt, forblive publiceret, da
          vægten allerede er noteret i systemet.
        </p>
        <div className="flex gap-5 mt-10">
          <AlertDialog.Cancel onClick={onCancelShipmondo} className="grow">
            Fortryd
          </AlertDialog.Cancel>

          <AlertDialog.Ok className="grow" onClick={onConfirmShipmondo}>
            Fortsæt med Shipmondo
          </AlertDialog.Ok>
        </div>
      </AlertDialog>
    </>
  );
};
