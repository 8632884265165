/* istanbul ignore file */

import { Inputs } from "../useStoreForm";
import { createPreviewObjects } from "~/components/Backroom/Preview";
import { Company } from "./Company";
import { Bank } from "./Bank";
import { Address } from "./Address";
import { CurrentStepProps } from "../getSideMenuItems";
import { ContinueButton } from "../ContinueButton";
import { createStripeAccountFromFormData } from "../createStripeAccountFromFormData";
import { formatValues } from "../formatValues";
import { useEffect, useState } from "react";
import { showToast } from "~/utils/toast";
import { handleError } from "~/utils/rollbar";
import { Dialog } from "../../Dialog";
import { ShippingStep, FIELDS as SHIPPING_FIELDS } from "../ShippingStep";

export const previewObjects = createPreviewObjects("companyDetailsStep", [
  {
    id: 1,
    title: "Placering af adresse på butikssiden",
    src: "company-details/desktop.png",
    alt: "Placering af adresse på butikssiden",
    width: 340,
    height: 420,
  },
  {
    id: 2,
    title: "Placering af adresse på butikssiden (mobil)",
    src: "company-details/mobil.png",
    alt: "Placering af adresse på butikssiden (mobil)",
    width: 200,
    height: 500,
  },
]);

export const FIELDS: Array<keyof Inputs> = [
  "bankAccountFirstName",
  "bankAccountLastName",
  "bankAccountIban",
  "bankAccountSwiftBic",

  "ownerFirstName",
  "ownerLastName",
  "ownerBirthday",
  "ownerEmail",
  "ownerPhone",
  "orderEmail",
  "cvr",

  "acceptsPickUp",
  "sameAddress",
  "showAddressOnMap",
  "companyAddress",
  "formattedcompanyAddress",
  "shownAddress",
  "formattedshownAddress",
  "location",
];

export const CompanyDetailsStep = (props: CurrentStepProps) => {
  const { form, onContinue, stepId, isSubmitting } = props;
  const { setValue, setError, getValues, watch } = form;
  const [isHandlingStripe, setIsHandlingStripe] = useState(false);
  const [openShippingModal, setOpenShippingModal] = useState(false);

  const stripeAccountId = watch("stripeAccountId");
  const acceptsPickUp = watch("acceptsPickUp");
  const deliveryType = watch("deliveryType");

  useEffect(() => {
    // If the user has not selected a delivery type, and does not accept pick up, we should open the shipping modal
    // This is to ensure that the user has selected a delivery type before continuing
    if (acceptsPickUp === "no" && deliveryType === "none") {
      setOpenShippingModal(true);
    }
  }, [acceptsPickUp]);

  const beforeContinue = async () => {
    // When the user has selected a delivery type, we should validate the shipping fields as well
    const relevantFields = form.formState.dirtyFields.deliveryType
      ? FIELDS.concat(SHIPPING_FIELDS)
      : FIELDS;
    const isValid = await form.trigger(relevantFields);

    if (!isValid) return;

    if (!stripeAccountId) {
      setIsHandlingStripe(true);

      const createdAccount = await createStripeAccountFromFormData(
        formatValues(getValues())
      ).catch((error) => {
        return { error };
      });

      setIsHandlingStripe(false);

      if ("error" in createdAccount) {
        const { error } = createdAccount;

        if (error.name) {
          setError(
            error.name,
            { type: "custom", message: error.message },
            { shouldFocus: true }
          );
        }

        showToast("danger", "Noget gik galt", "Prøv igen senere.");
        handleError(error);

        return;
      }

      setValue("stripeAccountId", createdAccount.accountId);
    }

    if (isValid) onContinue(stepId, relevantFields);
  };

  // This function is called when the user clicks the continue button in the shipping modal
  const beforeContinueWithShipping = async () => {
    let isValid = await form.trigger(FIELDS.concat(SHIPPING_FIELDS));

    if (
      getValues("acceptsPickUp") === "no" &&
      getValues("deliveryType") === "none"
    ) {
      isValid = false;
    }

    if (!isValid) return;

    setOpenShippingModal(false);
  };

  return (
    <>
      <h1 className="text-3xl mb-12">Virksomhedsoplysninger</h1>

      <Bank form={form} />
      <Company form={form} />
      <Address form={form} />
      <ContinueButton
        beforeContinue={beforeContinue}
        isSubmitting={isSubmitting || isHandlingStripe}
      />

      {stripeAccountId ? null : (
        <div className="mb-2">
          <small className="text-secondary-500 text-xs">
            Det kan tage lidt længere tid at gemme, fordi vi opretter dig i
            vores betalingssystem.
          </small>
        </div>
      )}

      <Dialog
        open={openShippingModal}
        trigger={null}
        onOpenChange={setOpenShippingModal}
        className="p-20 pb-8"
      >
        <ShippingStep {...props} onContinue={beforeContinueWithShipping} />
      </Dialog>
    </>
  );
};
