/* istanbul ignore file */

import { UseFormReturn } from "react-hook-form";
import { Inputs } from "../useStoreForm";
import Input from "~/components/Backroom/Input";
import PhoneInput from "../../PhoneInput";
import { validateAge } from "~/utils/validateAge";
import InfoModal from "~/components/Backroom/Infomodal";
import { EmailInput } from "../../EmailInput";

type CompanyProps = {
  form: UseFormReturn<Inputs>;
};

export const Company = ({
  form: {
    register,
    formState: { errors },
  },
}: CompanyProps) => {
  return (
    <section>
      <h2 className="text-2xl mb-2.5">Virksomhed</h2>
      <p className="text-secondary-800 mb-12">
        Følgende er oplysninger på din virksomhed. Disse oplysninger skal bruges
        til at oprette betalingskonti. Det eneste, der potentielt vil blive vist
        på butikssiden, er adressen.
      </p>

      <div>
        <div className="grid grid-cols-[1fr_1fr] gap-y-5 gap-x-2.5 mb-5">
          <Input
            id="ownerFirstName"
            label="Butiksejers fornavn:"
            placeholder="Hans"
            register={register}
            error={errors.ownerFirstName}
            registerOptions={{ required: "Fornavn er påkrævet" }}
          />
          <Input
            id="ownerLastName"
            label="Butiksejers efternavn:"
            placeholder="Jensen"
            register={register}
            error={errors.ownerLastName}
            registerOptions={{ required: "Efternavn er påkrævet" }}
          />
        </div>
        <Input
          id="ownerBirthday"
          label="Fødselsdato:"
          placeholder="01/01-2000"
          type="date"
          register={register}
          className="mb-5"
          error={errors.ownerBirthday}
          registerOptions={{
            required: "Fødselsdato er påkrævet",
            validate: (value) => {
              const isOldEnough = validateAge({
                birthdayDate: value,
                minAge: 18,
              });

              if (!isOldEnough)
                return "Du skal være over 18 år for at oprette en butik";
            },
          }}
        />

        <EmailInput
          id="ownerEmail"
          label="Virksomhedsemail:"
          placeholder="eksempel@mail.com"
          register={register}
          className="mb-5"
          error={errors.ownerEmail}
          registerOptions={{ required: "Email er påkrævet" }}
        />
        <PhoneInput
          id="ownerPhone"
          label="Virksomhedstelefon:"
          placeholder="38386030"
          register={register}
          className="mb-5"
          error={errors.ownerPhone}
          registerOptions={{ required: "Telefon er påkrævet" }}
        />
        <div className="relative mb-5">
          <div className="absolute right-0">
            <InfoModal id="orderEmailDescription" label="">
              <p className="mb-8">
                På denne e-mail adresse vil du blive notificeret, hver gang du
                modtager en ny ordre. Overvej at bruge en særskilt e-mail
                adresse eller sørg for at sætte en automatisk filtrering op i
                din indbakke, så du kan holde styr på f.eks. ordrer,
                kundehenvendelser og administrative e-mails.
              </p>
            </InfoModal>
          </div>
          <EmailInput
            id="orderEmail"
            label="Email til ordrer:"
            placeholder="eksempel@mail.com"
            register={register}
            error={errors.orderEmail}
            registerOptions={{ required: "Email til ordrer er påkrævet" }}
          />
        </div>
        <Input
          id="cvr"
          label="CVR:"
          placeholder="12345678"
          register={register}
          className="mb-5"
          error={errors.cvr}
          registerOptions={{
            required: "Butikkens cvr er påkrævet",
            validate: (value) => {
              if (!/^[0-9]{8}$/.test(value)) {
                return "Venligst indtast et korrekt cvr nummer";
              }
            },
          }}
        />
      </div>
    </section>
  );
};
