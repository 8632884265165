import Button from "~/components/Button";
import { Spinner } from "~/components/Spinner";

type Props = {
  beforeContinue: () => void;
  isSubmitting?: boolean;
};

export const ContinueButton = ({ beforeContinue, isSubmitting }: Props) => {
  return (
    <Button
      variant="primary"
      size="lg"
      className="w-full text-center flex justify-center"
      onClick={beforeContinue}
      disabled={isSubmitting}
    >
      {isSubmitting ? <Spinner /> : "Gem og fortsæt"}
    </Button>
  );
};
