import { Loader } from "@googlemaps/js-api-loader";

let googleApiClient: typeof google.maps.places;

export const getGoogleMapsApiClient = async () => {
  if (googleApiClient) {
    return googleApiClient;
  }

  if (!process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY) {
    return;
  }

  const loader = new Loader({
    apiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
    version: "weekly",
    libraries: ["places"],
  });

  const google = await loader.load();
  googleApiClient = google.maps.places;

  return googleApiClient;
};
