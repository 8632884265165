import { Inputs } from "../useStoreForm";
import { createPreviewObjects } from "~/components/Backroom/Preview";
import Input from "~/components/Backroom/Input";
import InfoModal from "~/components/Backroom/Infomodal";
import PhoneInput from "../../PhoneInput";
import { CurrentStepProps } from "../getSideMenuItems";
import { ContinueButton } from "../ContinueButton";
import { EmailInput } from "../../EmailInput";
import { OtherStoresSection } from "./OtherStoresSection";

export const previewObjects = createPreviewObjects("contactStep", [
  {
    id: 1,
    title: "Placering af kontaktoplysninger på butikssiden",
    src: "contact/desktop.png",
    alt: "Placering af kontaktoplysninger på butikssiden",
    width: 340,
    height: 420,
  },
  {
    id: 2,
    title: "Placering af kontaktoplysninger på butikssiden (mobil)",
    src: "contact/mobil.png",
    alt: "Placering af kontaktoplysninger på butikssiden (mobil)",
    width: 200,
    height: 500,
  },
]);

export const FIELDS: Array<keyof Inputs> = [
  "publicPhone",
  "publicEmail",
  "facebookUrl",
  "instagramUrl",
  "websiteUrl",
  "otherStores",
];

export const ContactStep = ({
  form,
  onContinue,
  stepId,
  isSubmitting,
}: CurrentStepProps) => {
  const { register, formState } = form;

  const beforeContinue = async () => {
    const isValid = await form.trigger(FIELDS);

    if (isValid) onContinue(stepId, FIELDS);
  };

  return (
    <>
      <h1 className="text-3xl mb-5">Kontakt</h1>
      <p className="mb-12">
        Disse informationer vil blive vist på butikssiden.
      </p>

      <div className="relative">
        <div className="absolute right-0">
          <InfoModal id="customerSupportPhoneDescription" label="">
            <p className="mb-8">
              Et telefonnummer dine kunder kan fange dig på. Det kan være en
              fordel at bruge et særskilt telefonnummer, så du ved hvilke
              opkald, der vedrører din butik.
            </p>
          </InfoModal>
        </div>

        <PhoneInput
          id="publicPhone"
          label="Telefonnummer til kundehenvendelser:"
          placeholder="38386030"
          register={register}
          className="mb-12"
          error={formState.errors.publicPhone}
          registerOptions={{ required: "Butikkens telefonnummer er påkrævet" }}
        />
      </div>
      <div className="relative">
        <div className="absolute right-0">
          <InfoModal id="customerSupportEmailDescription" label="">
            <p className="mb-8">
              Denne e-mail adresse vil blive brugt til kundehenvendelser.
              Overvej at bruge en særskilt e-mail adresse eller sørg for at
              sætte en automatisk filtrering op i din indbakke, så du kan holde
              styr på f.eks. ordrer, henvendelser og administrative e-mails.
            </p>
          </InfoModal>
        </div>
        <EmailInput
          id="publicEmail"
          label="E-mail til kundehenvendelser:"
          placeholder="eksempel@mail.dk"
          register={register}
          className="mb-12"
          error={formState.errors.publicEmail}
          registerOptions={{ required: "Butikkens email er påkrævet" }}
        />
      </div>

      <h1 className="text-2xl mb-5">Links</h1>
      <p className="mb-12">
        Her kan du vælge at linke til din hjemmeside og/eller sociale medier.
        Dette vil fremgå som links på din butiksside.
      </p>

      <Input
        id="websiteUrl"
        label="Link til din hjemmeside:"
        placeholder="https://butikdanmark.dk"
        register={register}
        className="mb-12"
      />

      <Input
        id="facebookUrl"
        label="Link til din Facebook-side:"
        placeholder="https://facebook.com/butikdanmark"
        register={register}
        className="mb-12"
      />

      <Input
        id="instagramUrl"
        label="Link til din Instagram-side:"
        placeholder="https://instagram.com/butikdanmark"
        register={register}
        className="mb-12"
      />

      <OtherStoresSection form={form} />

      <ContinueButton
        beforeContinue={beforeContinue}
        isSubmitting={isSubmitting}
      />
    </>
  );
};
