/* istanbul ignore file */

import { FieldError, UseFormReturn } from "react-hook-form";

import { Inputs } from "../useStoreForm";
import { RadioGroup } from "~/components/Backroom/RadioGroup";
import Image from "next/image";
import InfoModal from "~/components/Backroom/Infomodal";
import { AddressInput } from "~/components/Backroom/AddressInput";
import {
  formatAddress,
  getAddressComponents,
} from "../../AddressInput/googleMapsHelpers";
import { useMemo } from "react";

type AddressProps = {
  form: UseFormReturn<Inputs>;
};

export const Address = ({ form }: AddressProps) => {
  const {
    register,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState,
  } = form;

  const sameAddress = watch("sameAddress");
  const companyAddress = watch("companyAddress");
  const shownAddress = watch("shownAddress");
  const acceptsPickUp = watch("acceptsPickUp");

  const onSelectAddress = (
    place: google.maps.places.PlaceResult | null,
    addressKey: "shownAddress" | "companyAddress",
    setLocation?: boolean
  ) => {
    const { address, address2, city, postCode } =
      getAddressComponents(place) ?? {};

    if (!place || !address || !city || !postCode) {
      return setError(addressKey, {
        type: "manual",
        message: "Vælg venligst en adresse med husnummer fra listen.",
      });
    }

    setValue(addressKey, {
      line1: address,
      line2: address2,
      city,
      postCode,
    });
    setValue(
      `formatted${addressKey}`,
      formatAddress({ address, address2, city, postCode })
    );

    clearErrors(addressKey);
    clearErrors(`formatted${addressKey}`);

    if (setLocation && place.geometry?.location) {
      setValue("location", {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
    }
  };

  const formattedCompanyAddress = useMemo(() => {
    if (!companyAddress) return "";

    const { line1, line2, city, postCode } = companyAddress;
    return formatAddress({ address: line1, address2: line2, city, postCode });
  }, [JSON.stringify(companyAddress)]);

  const formattedShownAddress = useMemo(() => {
    if (!shownAddress) return "";

    const { line1, line2, city, postCode } = shownAddress;
    return formatAddress({ address: line1, address2: line2, city, postCode });
  }, [JSON.stringify(shownAddress)]);

  return (
    <section>
      <AddressInput
        register={register}
        id="companyAddress"
        label="Virksomhedsadresse:"
        placeholder="Storegade 1"
        onSelectPlace={(place) =>
          // sameAddress can be undefined, so we need to check if it is "no"
          onSelectAddress(place, "companyAddress", sameAddress !== "no")
        }
        initialValue={formattedCompanyAddress}
        className="mb-12"
        registerOptions={{
          required:
            "Virksomhedsadresse er påkrævet. Vælg en adresse fra listen.",
        }}
        error={
          (formState.errors.companyAddress ??
            formState.errors.formattedcompanyAddress) as FieldError
        }
      />
      <div className="flex flex-col gap-y-1.5 mb-12">
        <InfoModal
          id="acceptsPickUpDescription"
          label="Tager butikken imod kunder på en fysisk adresse?"
        >
          <ShownOnMapModalContent />
        </InfoModal>
        {control && (
          <RadioGroup
            id="acceptsPickUp"
            aria-describedby="acceptsPickUpDescription"
            control={control}
          >
            <RadioGroup.Item value="yes">Ja</RadioGroup.Item>
            <RadioGroup.Item value="no">Nej</RadioGroup.Item>
          </RadioGroup>
        )}
      </div>

      <>
        {acceptsPickUp === "yes" ? (
          <>
            <div className="flex flex-col gap-y-1.5 mb-12">
              <label id="sameAddressDescription">
                Er adressen den samme som virksomhedsadressen?
              </label>
              {control && (
                <RadioGroup
                  id="sameAddress"
                  aria-describedby="sameAddressDescription"
                  control={control}
                >
                  <RadioGroup.Item value="yes">Ja</RadioGroup.Item>
                  <RadioGroup.Item value="no">Nej</RadioGroup.Item>
                </RadioGroup>
              )}
            </div>
            {watch("sameAddress") === "no" ? (
              <AddressInput
                register={register}
                id="shownAddress"
                label="Vist adresse:"
                placeholder="Storegade 1"
                onSelectPlace={(place) =>
                  onSelectAddress(place, "shownAddress", sameAddress === "no")
                }
                initialValue={formattedShownAddress}
                className="mb-12"
                registerOptions={{
                  required:
                    "Vist adresse er påkrævet. Vælg en adresse fra listen.",
                }}
                error={
                  (formState.errors.shownAddress ??
                    formState.errors.formattedshownAddress) as FieldError
                }
              />
            ) : null}
          </>
        ) : (
          <div className="flex flex-col gap-y-1.5 mb-12">
            <InfoModal
              id="showAddressOnMapDescription"
              label="Ønsker du alligevel at blive vist på Butik Danmarks oversigtskort?"
            >
              <ShownOnMapModalContent />
            </InfoModal>

            {control && (
              <RadioGroup
                id="showAddressOnMap"
                aria-describedby="showAddressOnMapDescription"
                control={control}
              >
                <RadioGroup.Item value="yes">Ja</RadioGroup.Item>
                <RadioGroup.Item value="no">Nej</RadioGroup.Item>
              </RadioGroup>
            )}
          </div>
        )}
      </>
    </section>
  );
};

const ShownOnMapModalContent = () => (
  <>
    <Image
      src="/assets/images/google-map.png"
      alt="Din placering ved kortvisning uden fysisk butik"
      width={600}
      height={330}
      className="min-w-full rounded-lg mb-8"
    />
    <h4 className="text-3xl mb-5">Kortvisning uden fysisk butik</h4>
    <p className="mb-8">
      Modtager du ikke kunder på en fysisk adresse, kan du stadig få vist din
      butik på Butik Danmarks oversigtskort. I dette tilfælde bruger vi din
      virksomhedsadresse til at placere din butik på kortet.
    </p>
    <p className="mb-8">
      Vi skriver ikke din adresse på din butiksside, og sørger også for at
      fremhæve, på kortvisningen, at dine varer udelukkende kan handles online.
    </p>
    <p>
      At blive vist på oversigtskortet gør din butik mere synlig for potentielle
      kunder.
    </p>
  </>
);
