/* istanbul ignore file */

import { InfoBox } from "~/components/Backroom/InfoBox";
import Input from "~/components/Backroom/Input";
import { Textarea } from "~/components/Backroom/Textarea";
import { Inputs } from "../useStoreForm";
import { createPreviewObjects } from "~/components/Backroom/Preview";
import { CurrentStepProps } from "../getSideMenuItems";
import { ContinueButton } from "../ContinueButton";
import { RichTextInput } from "../../RichTextInput";

export const previewObjects = createPreviewObjects("storeDescription", [
  {
    id: 1,
    title: "Placering af navn & beskrivelse på butikssiden",
    src: "describe-store/desktop.png",
    alt: "Placering af navn & beskrivelse på butikssiden",
    width: 340,
    height: 420,
  },
  {
    id: 2,
    title: "Placering af navn & beskrivelse på butikssiden (mobil)",
    src: "describe-store/mobil.png",
    alt: "Placering af navn & beskrivelse på butikssiden (mobil)",
    width: 200,
    height: 500,
  },
  {
    id: 3,
    title: "Placering af navn & beskrivelse på kortvisning",
    src: "describe-store/desktop2.png",
    alt: "Placering af navn & beskrivelse på kortvisning",
    width: 340,
    height: 420,
  },
  {
    id: 4,
    title: "Placering af navn & beskrivelse på kortvisning (mobil)",
    src: "describe-store/mobil2.png",
    alt: "Placering af navn & beskrivelse på kortvisning (mobil)",
    width: 200,
    height: 500,
  },
  {
    id: 5,
    title: "Placering af navn på produktsiden",
    src: "describe-store/desktop3.png",
    alt: "Placering af navn på produktsiden",
    width: 340,
    height: 420,
  },
  {
    id: 6,
    title: "Placering af navn på produktsiden (mobil)",
    src: "describe-store/mobil3.png",
    alt: "Placering af navn på produktsiden (mobil)",
    width: 200,
    height: 500,
  },
]);

export const FIELDS: Array<keyof Inputs> = [
  "name",
  "introduction",
  "description",
];

export const DescriptionStep = (props: CurrentStepProps) => {
  const {
    stepId,
    form: { register, getValues, formState, trigger, setValue },
    isSubmitting,
    onContinue,
  } = props;

  const storeName = getValues("name");

  const beforeContinue = async () => {
    const isValid = await trigger(FIELDS);

    if (isValid) onContinue(stepId, FIELDS);
  };

  return (
    <>
      <h1 className="text-3xl mb-5">Beskriv din butik</h1>
      <p className="mb-12">
        {`Vi har oprettet din butik under navnet “${storeName}”. Hvis
        du ønsker at ændre det, kan du gøre det her. Desuden skal du også skrive
        en butiksbeskrivelse, så potentielle kunder kan læse om dig og din
        butik. En god og personlig butiksbeskrivelse øger chancen for, at
        potentielle kunder bliver til betalende kunder.`}
      </p>

      <div className="mb-12">
        <Input
          id="name"
          label="Angiv butikkens navn:"
          placeholder="Butikkens navn"
          register={register}
          className="mb-12"
          error={formState.errors.name}
          registerOptions={{
            required: "Butikkens navn er påkrævet",
          }}
        />
        <Textarea
          id="introduction"
          label="Tilføj en kort introduktion:"
          placeholder="Kort introduktion som står før butiksbeskrivelsen"
          register={register}
          className="mb-12"
          error={formState.errors.introduction}
          maxLength={120}
          rows={2}
          registerOptions={{
            required: "Butikkens introduktion er påkrævet",
            minLength: {
              value: 8,
              message: "Butikkens introduktion skal være mindst 8 tegn lang.",
            },
          }}
        />
        <RichTextInput
          id="description"
          label="Beskriv butikken:"
          placeholder="Tilføj butiksbeskrivelse"
          className="mb-12"
          error={formState.errors.description}
          initialValue={getValues("description")}
          onChange={(value) => {
            // we added this regex to allow user to leave empty line in the editor
            const newValue = value.replace(/\n{2}(\n{2})+/g, (m) => {
              return m
                .split(/(\n\n)/)
                .filter(Boolean)
                .join("​");
            });
            setValue("description", newValue, { shouldDirty: true });
          }}
          registerOptions={{
            required: "Butikkens beskrivelse er påkrævet",
            minLength: {
              value: 8,
              message: "Butikkens beskrivelse skal være mindst 8 tegn lang.",
            },
          }}
        />
      </div>

      <div className="flex flex-col-reverse lg:flex-col gap-12">
        <InfoBox>
          <ul className="list-disc pl-6">
            <li>Du kan ændre navnet senere.</li>
            <li>Du kan ændre teksten senere.</li>
            <li>Brug “jeg” eller “vi” for at gøre teksten personlig.</li>
            <li>
              Forestil dig, at du er en potentiel kunde, der skal læse om dig og
              butikken. Hvad vil du gerne vide?
            </li>
            <li>
              Tilføj emojis 👍😊❤️ til din tekst ved brug af denne genvej på
              MAC: Ctrl + Cmd + Space
            </li>
            <li>
              Tilføj emojis 🤝😍🌈 til din tekst ved brug af denne genvej på PC:
              Windows + .{" "}
            </li>
          </ul>
        </InfoBox>
        <ContinueButton
          beforeContinue={beforeContinue}
          isSubmitting={isSubmitting}
        />
      </div>
    </>
  );
};
