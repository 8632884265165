import classNames from "classnames";
import UncontrolledInput, {
  UncontrolledInputProps,
} from "../UncontrolledInput";
import { Suggestions, useAddressInput } from "../AddressInput/useAddressInput";
import { useRef } from "react";
import {
  formatAddress,
  getAddressComponents,
} from "../AddressInput/googleMapsHelpers";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelectPlace: (place: google.maps.places.PlaceResult | null) => void;
  suggestionsClassName?: string;
} & UncontrolledInputProps;

export const UncontrolledAddressInput = (props: Props) => {
  const { onSelectPlace, className, suggestionsClassName, ...restProps } =
    props;
  const formattedAddressRef = useRef<HTMLInputElement>(null);

  const beforeOnSelectPlace = (
    place: google.maps.places.PlaceResult | null
  ) => {
    const components = getAddressComponents(place);
    if (place && components && formattedAddressRef.current) {
      formattedAddressRef.current.value = formatAddress(components);
    }

    onSelectPlace(place);
  };

  const { loadSuggestions, suggestions, onSelectSuggestion } = useAddressInput({
    onSelectPlace: beforeOnSelectPlace,
  });

  return (
    <div className={classNames("relative", className)}>
      <UncontrolledInput
        {...restProps}
        ref={formattedAddressRef}
        onChange={async (event) => {
          loadSuggestions(event.target.value);
        }}
      />

      <Suggestions
        suggestions={suggestions}
        onSuggestionSelected={onSelectSuggestion}
        className={suggestionsClassName}
      />

      {/* The Places API requires a node to put attribution details */}
      <div id="googlemaps-attribution-container"></div>
    </div>
  );
};
