/* istanbul ignore file */

import { UseFormReturn, useForm } from "react-hook-form";
import { Inputs } from "../useStoreForm";
import { createPreviewObjects } from "~/components/Backroom/Preview";
import { InfoBox } from "~/components/Backroom/InfoBox";
import { Accordion } from "~/components/Backroom/Accordion";
import { InputError } from "~/components/Backroom/InputError";
import { RadioGroup } from "~/components/Backroom/RadioGroup";
import { useEffect, useState } from "react";
import { OpeningHourObject, OpeningHours } from "~/shared-types";
import classNames from "classnames";
import { OpeningHoursHeader } from "./OpeningHoursHeader";
import { OpeningHoursInputs } from "./OpenHoursInputs";
import { useUpdateEffect } from "usehooks-ts";
import Input from "~/components/Backroom/Input";
import { CurrentStepProps } from "../getSideMenuItems";
import { ContinueButton } from "../ContinueButton";

export const previewObjects = createPreviewObjects("openingHours", [
  {
    id: 1,
    title: "Placering af åbningstider på butikssiden",
    src: "opening-hours/desktop.png",
    alt: "Placering af åbningstider på butikssiden",
    width: 340,
    height: 420,
  },
  {
    id: 2,
    title: "Placering af åbningstider på butikssiden (mobil)",
    src: "opening-hours/mobil.png",
    alt: "Placering af åbningstider på butikssiden (mobil)",
    width: 200,
    height: 500,
  },
  {
    id: 3,
    title: "Placering af åbningstider på kortvisning",
    src: "opening-hours/desktop2.png",
    alt: "Placering af åbningstider på kortvisning",
    width: 340,
    height: 420,
  },
  {
    id: 4,
    title: "Placering af åbningstider på kortvisning (mobil)",
    src: "opening-hours/mobil2.png",
    alt: "Placering af åbningstider på kortvisning (mobil)",
    width: 200,
    height: 500,
  },
]);

type WeekdayRadioGroupProps = {
  openingHours?: OpeningHourObject;
  onUpdate: (openingHours: OpeningHourObject) => void;
};

export function WeekdayRadioGroup({
  openingHours,
  onUpdate,
}: WeekdayRadioGroupProps) {
  const form = useForm<{ inputType: "open" | "openByAgreement" | "closed" }>({
    defaultValues: { inputType: openingHours?.type ?? "closed" },
  });
  const inputType = form.watch("inputType");

  useUpdateEffect(() => {
    onUpdate({ type: inputType, hours: [] });
  }, [inputType]);

  const onInput = (hours: OpeningHourObject["hours"]) => {
    onUpdate({ type: inputType, hours });
  };

  return (
    <RadioGroup
      id={"inputType"}
      aria-label={`Åbningstider for ${inputType}`}
      control={form.control}
      defaultValue={inputType}
    >
      <RadioGroup.Item value="open">Faste Åbningstider</RadioGroup.Item>
      {inputType === "open" ? (
        <OpeningHoursInputs hours={openingHours?.hours} onUpdate={onInput} />
      ) : null}
      <RadioGroup.Item value="openByAgreement">
        Åben efter aftale
      </RadioGroup.Item>
      {inputType === "openByAgreement" ? (
        <OpeningHoursInputs hours={openingHours?.hours} onUpdate={onInput} />
      ) : null}
      <RadioGroup.Item value="closed">Lukket</RadioGroup.Item>
    </RadioGroup>
  );
}

const weekdays = [
  { weekday: "Mandag", hoursKey: "monday" },
  { weekday: "Tirsdag", hoursKey: "tuesday" },
  { weekday: "Onsdag", hoursKey: "wednesday" },
  { weekday: "Torsdag", hoursKey: "thursday" },
  { weekday: "Fredag", hoursKey: "friday" },
  { weekday: "Lørdag", hoursKey: "saturday" },
  { weekday: "Søndag", hoursKey: "sunday" },
] as const;

const checkValidOpeningHours = (
  openingHours: OpeningHours
): Array<[keyof OpeningHours, boolean]> => {
  return Object.entries(openingHours).map(([key, value]) => {
    if (value.type === "closed")
      return [key, true] as [keyof OpeningHours, boolean];

    if (!value.hours || value.hours.length === 0) {
      return [key, false] as [keyof OpeningHours, boolean];
    }

    if (value.hours.some((hour) => !hour.from || !hour.to)) {
      return [key, false] as [keyof OpeningHours, boolean];
    }

    return [key, true] as [keyof OpeningHours, boolean];
  });
};

export const validateOpeningsHours = (
  openingHours: OpeningHours,
  form: UseFormReturn<Inputs>
) => {
  const validations = checkValidOpeningHours(openingHours);
  validations.forEach((validation) => {
    const [key, isValid] = validation;
    if (isValid) {
      return form.clearErrors(`openingHours.${key}`);
    }

    form.setError(`openingHours.${key}`, {
      type: "manual",
      message: "Åbningstider skal være komplet udfyldt med fra og til tider.",
    });
  });
  return validations.every(([, isValid]) => isValid);
};

export const FIELDS: Array<keyof Inputs> = [
  "openingHours",
  "openingHoursDescription",
];

export const OpeningHoursStep = ({
  form,
  onContinue,
  stepId,
  isSubmitting,
}: CurrentStepProps) => {
  const { formState, getValues, clearErrors, register } = form;
  const [openingHours, setOpeningHours] = useState<
    OpeningHours | null | undefined
  >(getValues("openingHours"));

  useEffect(() => {
    form.setValue("openingHours", openingHours);
  }, [JSON.stringify(openingHours)]);

  const onUpdate = (
    hoursKey: string,
    updatedOpeningHours: OpeningHourObject
  ) => {
    clearErrors("openingHours");
    setOpeningHours((prev) => ({
      ...(prev ?? {}),
      [hoursKey]: updatedOpeningHours,
    }));
  };

  const beforeContinue = async () => {
    const isValid = !openingHours || validateOpeningsHours(openingHours, form);

    if (isValid) onContinue(stepId, FIELDS);
  };

  return (
    <>
      <h1 className="text-3xl mb-5">Åbningstider</h1>
      <p className="mb-12">
        Her skal du, for hver ugedag, angive dine åbningstider. Brug et
        24-timers tidssystem (f.eks. 09:00 til 17:30).
      </p>

      <div className="mb-12">
        <Accordion className="mb-12">
          {weekdays.map(({ weekday, hoursKey }) => (
            <div
              key={weekday}
              className={classNames(
                "relative",
                formState?.errors[hoursKey] ? "mb-10" : "mb-5"
              )}
            >
              <Accordion.Item value={weekday} title={weekday}>
                <Accordion.Trigger>
                  <OpeningHoursHeader
                    dayName={weekday}
                    openingHours={openingHours?.[hoursKey]}
                  />
                </Accordion.Trigger>
                <Accordion.Content>
                  <WeekdayRadioGroup
                    openingHours={openingHours?.[hoursKey]}
                    onUpdate={(value) => onUpdate(hoursKey, value)}
                  />
                </Accordion.Content>
              </Accordion.Item>
              <InputError className="absolute top-full text-xs">
                {formState?.errors.openingHours?.[hoursKey]?.message}
              </InputError>
            </div>
          ))}
        </Accordion>

        <Input
          id="openingHoursDescription"
          label="Ekstra information om åbningstider:"
          placeholder="Du er altid velkommen til at ringe..."
          register={register}
        />
      </div>

      <div className="flex flex-col-reverse lg:flex-col gap-12">
        <InfoBox>
          <ul className="list-disc pl-6">
            <li>
              Dine åbningstider vises fordi du har sagt ja til at modtage kunder
              på din adresse.
            </li>
            <li>
              Du kan indtaste flere åbningstider for samme dag ved at trykke på
              “+”.
            </li>
          </ul>
        </InfoBox>
        <ContinueButton
          beforeContinue={beforeContinue}
          isSubmitting={isSubmitting}
        />
      </div>
    </>
  );
};
