import classNames from "classnames";
import UncontrolledInput, {
  UncontrolledInputProps,
} from "../UncontrolledInput";
import { RegisterOptions, UseFormRegister } from "react-hook-form";
import { Suggestions, useAddressInput } from "./useAddressInput";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
  registerOptions?: RegisterOptions;
  onSelectPlace: (place: google.maps.places.PlaceResult | null) => void;
  suggestionsClassName?: string;
} & UncontrolledInputProps;

export const AddressInput = (props: Props) => {
  const {
    id,
    register,
    registerOptions,
    onSelectPlace,
    className,
    suggestionsClassName,
    ...restProps
  } = props;
  const addressRegistration = register(id, registerOptions);
  const formattedAddressRegistation = register(
    `formatted${id}`,
    registerOptions
  );

  const { loadSuggestions, suggestions, onSelectSuggestion } = useAddressInput({
    onSelectPlace,
  });

  return (
    <div className={classNames("relative", className)}>
      <UncontrolledInput
        className="hidden"
        id={id}
        registerOptions={registerOptions}
        {...addressRegistration}
        {...restProps}
        label=""
      />
      <UncontrolledInput
        id={`formatted${id}`}
        registerOptions={registerOptions}
        {...formattedAddressRegistation}
        {...restProps}
        onChange={async (event) => {
          loadSuggestions(event.target.value);
        }}
      />

      <Suggestions
        suggestions={suggestions}
        onSuggestionSelected={onSelectSuggestion}
        className={suggestionsClassName}
      />

      {/* The Places API requires a node to put attribution details */}
      <div id="googlemaps-attribution-container"></div>
    </div>
  );
};
