import classNames from "classnames";
import { showToast } from "~/utils/toast";

import {
  getAddressComponents,
  getAutocompleteSessionToken,
  getPlaceDetails,
  getPlacePredictions,
} from "./googleMapsHelpers";
import { useCallback, useRef, useState } from "react";

type SuggestionsProps = {
  suggestions: google.maps.places.AutocompletePrediction[];
  onSuggestionSelected: (
    suggestion: google.maps.places.AutocompletePrediction
  ) => void;
  className?: string;
};

export const Suggestions = ({
  suggestions,
  onSuggestionSelected,
  className,
}: SuggestionsProps) => {
  if (suggestions.length === 0) return null;

  return (
    <ul
      className={classNames(
        "bg-secondary-100 rounded-md text-sm flex flex-col gap-2 py-2 overflow-hidden",
        className
      )}
    >
      {suggestions.map((suggestion) => (
        <li
          key={suggestion.place_id}
          onClick={() => {
            onSuggestionSelected(suggestion);
          }}
          className="hover:underline cursor-pointer py-1 px-2"
        >
          {suggestion.description}
        </li>
      ))}
    </ul>
  );
};

type UseAddressInputParams = {
  onSelectPlace: (place: google.maps.places.PlaceResult | null) => void;
};

export const useAddressInput = ({ onSelectPlace }: UseAddressInputParams) => {
  const [suggestions, setSuggestions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const sessionTokenRef = useRef<google.maps.places.AutocompleteSessionToken>();

  const loadSuggestions = useCallback((inputValue: string) => {
    clearTimeout(timeoutRef.current);

    if (!inputValue || inputValue.trim().length <= 3) {
      setSuggestions([]);
      onSelectPlace(null);
      return;
    }

    // debounce the loading of suggestions to reduce usage of the Google API
    timeoutRef.current = setTimeout(async () => {
      if (!sessionTokenRef.current) {
        const sessionToken = await getAutocompleteSessionToken();
        if (!sessionToken) {
          return;
        }

        sessionTokenRef.current = sessionToken;
      }

      const { predictions, status } = await getPlacePredictions(
        inputValue,
        sessionTokenRef.current
      );

      if (status === "ZERO_RESULTS" || status !== "OK" || !predictions) {
        setSuggestions([]);
        return;
      }

      setSuggestions(predictions);
    }, 350);
  }, []);

  const onSelectSuggestion = useCallback(
    async (suggestion: google.maps.places.AutocompletePrediction) => {
      // clear suggestion list
      setSuggestions([]);

      // Clear the session token, it can only be used in one request
      const sessionToken = sessionTokenRef.current;
      sessionTokenRef.current = undefined;

      const { placeDetails, status } = await getPlaceDetails(
        suggestion.place_id,
        document.getElementById(
          "googlemaps-attribution-container"
        ) as HTMLDivElement,
        sessionToken
      );

      if (status !== "OK") {
        showToast("danger", "Vi kunne ikke finde adressen.", "Prøv igen.");
        return;
      }
      const components = getAddressComponents(placeDetails);

      if (!components?.address) {
        showToast("danger", "Skriv venligst en præcis adresse.", "Prøv igen.");
        return;
      }

      onSelectPlace(placeDetails);
    },
    []
  );

  return {
    suggestions,
    loadSuggestions,
    onSelectSuggestion,
  };
};
